import { Injectable } from "@angular/core";
import { AppContext } from "../contexts/app-context";
import { UserCredentials } from "../models/domains/credentials/user-credentials.model";
import { Address } from "../models/domains/pdv/address";
import { FiscalNumber } from "../models/domains/pdv/fiscal-number";
import { Network } from "../models/domains/pdv/network";
import { PdvPassport } from "../models/domains/pdv/pdv-passport";
import { AuthRepository } from "../repositories/auth.repository";
import { UserService } from "./user.service";

@Injectable()
export class AuthService {
  constructor(private authRepository: AuthRepository, private appContext: AppContext, private userService: UserService) {}

  public async authenticatePdv(pdvPassport: PdvPassport): Promise<Network> {
    try {
      const network = await this.authRepository.authenticatePdv(pdvPassport);
      const address = await this.getNetworkAddressByFiscalNumber(network.getFiscalNumber());
      network.setAddress(address);
      this.userService.updatePdvPassport(pdvPassport);
      this.userService.updateNetwork(network);
      return network;
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  private async getNetworkAddressByFiscalNumber(fiscalNumber: FiscalNumber): Promise<Address> {
    return await this.authRepository.getNetworkAddressByFiscalNumber(fiscalNumber);
  }

  public async authenticate(username: string, password: string): Promise<UserCredentials> {
    try {
      const userCredentials = await this.authRepository.login(username, password);
      this.appContext.setUserCredential(userCredentials);
      return Promise.resolve(userCredentials);
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  logout(): void {
    this.appContext.clearUserCredential();
    this.appContext.clearServiceCredential();
  }

  async isAuthenticated(): Promise<boolean> {
    const credential = this.appContext.getUserCredential();
    if (credential && credential.token) {
      return await this.authRepository.validateUserToken(credential.token);
    }
    return false;
  }

  async isServiceTokenValid(token: string): Promise<boolean> {
    if (token) {
      return await this.authRepository.validateServiceToken(token);
    }
    return false;
  }

  public isUserLogged(): boolean {
    const userCredentials = this.appContext.getUserCredential();
    return !!userCredentials && !!userCredentials.token;
  }

  public isServiceLogged(): boolean {
    const serviceCredentials = this.appContext.getServiceCredential();
    return !!serviceCredentials && !!serviceCredentials.token;
  }

  public async switchContext(switchContextHash: string): Promise<UserCredentials> {
    try {
      const hash = JSON.parse(atob(switchContextHash));
      const userCredentials = await this.authRepository.switchContext(hash.UserId, hash.RefreshToken, hash.SourceApplicationId);
      this.appContext.setUserCredential(userCredentials);
      return Promise.resolve(userCredentials);
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  getToken(): string {
    let token = "";
    if (this.isUserLogged()) {
      token = this.appContext.getUserCredential().token;
    } else if (this.isServiceLogged()) {
      token = this.appContext.getServiceCredential().token;
    }
    return token;
  }
}
