export class ErrorCause {
  private errorCode: string;
  private errorMessage: string;
  private errorException: string;

  setErrorCode(value: string): void {
    this.errorCode = value;
  }
  getErrorCode(): string {
    return this.errorCode;
  }
  setErrorMessage(value: string): void {
    this.errorMessage = value;
  }
  getErrorMessage(): string {
    return this.errorMessage;
  }
  setErrorException(value: string): void {
    this.errorException = value;
  }
  getErrorException(): string {
    return this.errorException;
  }
}
