import { ErrorCause } from "./error-cause";
import { Network } from "./network";
import { SalesOrderItemResult } from "./sales-order-item-result";
import { Shopper } from "./shopper";
import { TaxCoupon } from "./tax-coupon";
import { UniqueSequentialNumber } from "./unique-sequential-number";

export class SalesOrderResult {
  private uniqueSequentialNumber: UniqueSequentialNumber;
  private shopper: Shopper;
  private transactionDate: Date;
  private salesAction: string;
  private network: Network;
  private salesOrderItemResultList: SalesOrderItemResult[];
  private errorCauseList: ErrorCause[];
  private taxCoupon: TaxCoupon;
  private totalCardValue: number;
  private totalDiscountValue: number;
  private totalGrossPrice: number;
  private totalPrice: number;
  private totalSubsidyValue: number;
  private totalEmploymentPayrollValue: number;
  private totalCheckoutValue: number;

  setUniqueSequentialNumber(value: UniqueSequentialNumber): void {
    this.uniqueSequentialNumber = value;
  }

  getUniqueSequentialNumber(): UniqueSequentialNumber {
    return this.uniqueSequentialNumber;
  }

  setShopper(value: Shopper): void {
    this.shopper = value;
  }

  getShopper(): Shopper {
    return this.shopper;
  }

  setTransactionDate(value: Date): void {
    this.transactionDate = value;
  }

  getTransactionDate(): Date {
    return this.transactionDate;
  }

  getTransactioDateSimple(): string {
    return `${this.transactionDate.getDate()}/${this.transactionDate.getMonth() + 1}/${this.transactionDate.getFullYear()}`;
  }

  getTransactionHour(): string {
    return this.transactionDate.toString().slice(16, 24);
  }

  setSalesAction(value: string): void {
    this.salesAction = value;
  }

  getSalesAction(): string {
    return this.salesAction;
  }

  setNetwork(value: Network): void {
    this.network = value;
  }

  getNetwork(): Network {
    return this.network;
  }

  setSalesOrderItemResultList(value: SalesOrderItemResult[]): void {
    this.salesOrderItemResultList = value;
  }

  getSalesOrderItemResultList(): SalesOrderItemResult[] {
    return this.salesOrderItemResultList;
  }

  getSalesOrderItemListLength(): number {
    return this.salesOrderItemResultList.length;
  }

  hasAllowedProducts(): boolean {
    return this.salesOrderItemResultList.some((item) => item.getProductQuantity() > 0);
  }
  setErrorCauseList(value: ErrorCause[] = []): void {
    this.errorCauseList = value;
  }

  getErrorCauseList(): ErrorCause[] {
    return this.errorCauseList;
  }

  setTaxCoupon(value: TaxCoupon): void {
    this.taxCoupon = value;
  }

  getTaxCoupon(): TaxCoupon {
    return this.taxCoupon;
  }

  setTotalCardValue(value: number): void {
    this.totalCardValue = value;
  }

  getTotalCardValue(): number {
    return this.totalCardValue;
  }

  setTotalDiscountValue(value: number): void {
    this.totalDiscountValue = value;
  }

  getTotalDiscountValue(): number {
    return this.totalDiscountValue;
  }

  setTotalGrossPrice(value: number): void {
    this.totalGrossPrice = value;
  }

  getTotalGrossPrice(): number {
    return this.totalGrossPrice;
  }

  setTotalPrice(value: number): void {
    this.totalPrice = value;
  }

  getTotalPrice(): number {
    return this.totalPrice;
  }

  setTotalSubsidyValue(value: number): void {
    this.totalSubsidyValue = value;
  }

  getTotalSubsidyValue(): number {
    return this.totalSubsidyValue;
  }

  setTotalEmploymentPayrollValue(value: number): void {
    this.totalEmploymentPayrollValue = value;
  }

  getTotalEmploymentPayrollValue(): number {
    return this.totalEmploymentPayrollValue;
  }

  setTotalCheckoutValue(value: number): void {
    this.totalCheckoutValue = value;
  }

  getTotalCheckoutValue(): number {
    return this.totalCheckoutValue;
  }
}
