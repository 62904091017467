import { Component } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { MatIconRegistry } from "@angular/material";
import { Observable } from "rxjs";
import { AuthLoadingService } from "./ui/services/pdv/auth-loading.service";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "idp-vpi-app";
  isAuthenticating: Observable<boolean>;

  private customIcons: Array<{ name: string; fileName: string }> = [
    { name: "consult", fileName: "refund-icon.svg" },
    { name: "home", fileName: "home-icon.svg" },
    { name: "register", fileName: "register-icon.svg" },
    { name: "extract", fileName: "extract-icon.svg" },
    { name: "suport", fileName: "suport-icon.svg" },
    { name: "exit-app", fileName: "exit-app-icon.svg" },
    { name: "excel", fileName: "excel-icon.svg" },
  ];

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer, private authLoadingService: AuthLoadingService) {
    this.addCustomIcons();
    this.isAuthenticating = this.authLoadingService.getAuthStatus();
  }

  private addCustomIcons() {
    this.customIcons.forEach((customIcon) => {
      const defaultIconsPath = "../assets/icons/";
      this.matIconRegistry.addSvgIcon(customIcon.name, this.domSanitizer.bypassSecurityTrustResourceUrl(defaultIconsPath + customIcon.fileName));
    });
  }
}
