import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material";
import { Router } from "@angular/router";
import { AppContext } from "src/app/ui/contexts/app-context";
import { Product } from "src/app/ui/models/domains/pdv/product";
import { SalesOrderItem } from "src/app/ui/models/domains/pdv/sales-order-item";
import { SalesOrderItemResult } from "src/app/ui/models/domains/pdv/sales-order-item-result";
import { SalesOrderResult } from "src/app/ui/models/domains/pdv/sales-order-result";
import { Shopper } from "src/app/ui/models/domains/pdv/shopper";
import { RoutePath } from "src/app/ui/models/domains/route-paths.model";
import { LoadingService } from "src/app/ui/services/loading.service";
import { PurchaseService } from "src/app/ui/services/pdv/purchase.service";
import { SalesService } from "src/app/ui/services/pdv/sales.service";
import { ShopperService } from "src/app/ui/services/pdv/shopper.service";
import { ConfirmDialog } from "../../../dialog/confirm-dialog/confirm-dialog.component";
import { Toast } from "../../../toast/toast";
import { SearchProductBar } from "../../purchase-content/search-product-bar/search-product-bar.component";
import { RefundedDialog } from "../refund-dialog/refunded-dialog.component";

@Component({
  selector: "pdv-sale-details",
  templateUrl: "./sale-details.component.html",
  styleUrls: ["./sale-details.component.scss"],
})
export class SaleDetailsComponent implements OnInit {
  public reuseItems: boolean = true;
  private confirmDialogRef: MatDialogRef<ConfirmDialog>;
  private refundedDialogRef: MatDialogRef<RefundedDialog>;
  constructor(
    private salesService: SalesService,
    private dialog: MatDialog,
    private loadingService: LoadingService,
    private toast: Toast,
    private purchaseService: PurchaseService,
    private searchProductBar: SearchProductBar,
    private router: Router,
    private appContext: AppContext,
    private shopperService: ShopperService,
  ) {}
  ngOnInit(): void {}

  getSelectedSale(): SalesOrderResult {
    return this.salesService.getSelectedSale();
  }

  hasSelectedSale(): boolean {
    return !!this.getSelectedSale();
  }

  getShopper(): Shopper {
    if (this.hasSelectedSale()) {
      return this.getSelectedSale().getShopper();
    }
  }

  getItems(): Array<SalesOrderItemResult> {
    if (this.hasSelectedSale()) {
      return this.getSelectedSale().getSalesOrderItemResultList();
    }
  }

  getTotalPrice(): number {
    let totalPrice = 0;
    this.getItems().forEach((item) => {
      totalPrice += item.getPrice();
    });
    return totalPrice;
  }

  getTotalCheckoutValue(): number {
    let totalCheckoutValue = 0;
    this.getItems().forEach((item) => {
      totalCheckoutValue += item.getCheckoutValue();
    });
    return totalCheckoutValue;
  }

  getTotalCardValue(): number {
    let totalCardValue = 0;
    this.getItems().forEach((item) => {
      totalCardValue += item.getCardValue();
    });
    return totalCardValue;
  }

  refundSale() {
    const sale = this.getSelectedSale();
    this.confirmDialogRef = this.dialog.open(ConfirmDialog, {
      disableClose: true,
      hasBackdrop: true,
      height: "250px",
      width: "500px",
      data: {
        title: "Atenção!",
        text: "Confirma a devolução de TODOS os itens da NSU: " + sale.getUniqueSequentialNumber().getValue() + "?",
        cancelText: "NÃO",
      },
    });

    this.confirmDialogRef.componentInstance.confirmDialogEvent.subscribe(async () => {
      this.emitRefund();
    });
  }

  private async emitRefund() {
    const refLoad = this.loadingService.beginLoading();
    const sale = this.getSelectedSale();
    try {
      await this.salesService.refundSale(sale);
      this.showSuccessRefund();
    } catch (error) {
      console.error(error);
      this.toast.showWarningToast("Erro ao efetuar devolução. " + error);
    } finally {
      refLoad.closeLoading();
    }
  }

  showSuccessRefund() {
    const sale = this.getSelectedSale();
    this.refundedDialogRef = this.dialog.open(RefundedDialog, {
      disableClose: true,
      hasBackdrop: true,
      height: "330px",
      width: "550px",
      data: {
        nsu: sale.getUniqueSequentialNumber().getValue(),
        refundValue: this.getTotalCheckoutValue(),
        reuseItems: this.reuseItems,
      },
    });

    this.refundedDialogRef.componentInstance.refundedDialogEvent.subscribe(
      //só vai chamar aqui se estiver marcado para aproveitar carrihno
      async () => {
        this.createNewPurchase();
      },
    );

    this.refundedDialogRef.componentInstance.cancelDialogEvent.subscribe(
      //marcado para NÃO aproveitar carrihno
      async () => {
        this.salesService.setSelectedSale(null);
        this.salesService.setSalesFoundList(new Array<SalesOrderResult>());
      },
    );
  }

  private async createNewPurchase() {
    try {
      //debugger;
      await this.goToHome();

      const sale = this.getSelectedSale();
      await this.shopperService.searchBeneficiary(sale.getShopper().getSocialNumber().getValue());
      this.getItems().forEach(async (item) => {
        await this.searchProductBar.addProduct(item.getProductResult().getEan());
        const quantity = item.getAuthorizedQuantity();
        if (quantity > 1) {
          const product = new Product();
          product.setEan(item.getProductResult().getEan());
          for (let i = 1; i < quantity; i++) {
            this.purchaseService.addSalesOrderItemQuantity(new SalesOrderItem(product, null));
          }
        }
      });
    } catch (error) {
      this.toast.showWarningToast("Erro ao gerar nova Venda. Vá para a página inicial e execute manualmente.");
    } finally {
      this.salesService.setSelectedSale(null);
      this.salesService.setSalesFoundList(new Array<SalesOrderResult>());
    }
  }

  async goToHome(): Promise<void> {
    const pdvPassport = this.appContext.getPdvPassport();
    await this.router.navigate([RoutePath.Home], {
      queryParams: {
        t: pdvPassport.getToken(),
        f: pdvPassport.getNetworkFiscalNumber(),
        c: pdvPassport.getClientId(),
        u: pdvPassport.getOperatorUserName(),
      },
    });
  }
}
