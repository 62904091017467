import { IConfigMedicalPrescription } from "src/app/ui/repositories/parsers/pdv/does-product-require-scan.parser";
import { ErrorCause } from "./error-cause";
import { MedicalPrescription } from "./medical-prescription";
import { Product } from "./product";
import { SalesOrderItemResult } from "./sales-order-item-result";

export class SalesOrderItem {
  private static key = 1;
  private id: number;
  private productQuantity: number;
  private networkPrice: number;
  private networkGrossPrice: number;
  private medicalPrescription: MedicalPrescription;
  private errorCauseList: ErrorCause[] = [];

  constructor(private readonly product: Product, private readonly configMedicalPrescription: IConfigMedicalPrescription) {
    this.id = SalesOrderItem.key++;
    this.productQuantity = 1;
    this.medicalPrescription = new MedicalPrescription();
  }

  public getKey(): number {
    return this.id;
  }

  public getProduct(): Product {
    return this.product;
  }

  public addQuantity(): void {
    this.productQuantity = this.productQuantity + 1;
  }

  public removeQuantity(): void {
    this.productQuantity = this.productQuantity - 1;
  }

  public setProductQuantity(value: number): void {
    this.productQuantity = value;
  }

  public getProductQuantity(): number {
    return this.productQuantity;
  }

  public getAuthorizedQuantity(): number {
    return this.product.getAuthorizedQuantity();
  }

  public setNetworkPrice(value: number): void {
    this.networkPrice = value;
  }

  public getNetworkPrice(): number {
    return this.networkPrice;
  }

  public setNetworkGrossPrice(value: number): void {
    this.networkGrossPrice = value;
  }

  public getNetworkGrossPrice(): number {
    return this.networkGrossPrice;
  }

  public getMedicalPrescription(): MedicalPrescription {
    return this.medicalPrescription;
  }

  public setMedicalPrescription(value: MedicalPrescription) {
    if (!value) {
      throw "MedicalPrescription can't be null";
    }
    this.medicalPrescription = value;
  }

  public getRequireMedicalPrescription(): boolean {
    if (!this.configMedicalPrescription) {
      return false;
    }
    return this.configMedicalPrescription.enabled == true;
  }

  public getRequireScan(): boolean {
    if (!this.configMedicalPrescription) {
      return false;
    }
    return this.configMedicalPrescription.requiredScan == true;
  }

  public getPurchaseRequireScan(): boolean {
    if (!this.configMedicalPrescription) {
      return false;
    }
    return this.configMedicalPrescription.purchaseRequiredScan == true;
  }

  public getTotalPrice() {
    return (this.product.getPrice() * this.productQuantity).toFixed(2);
  }

  public setErrorCauseList(value: ErrorCause[]): void {
    this.errorCauseList = value;
  }

  public getErrorCauseList(): ErrorCause[] {
    return this.errorCauseList;
  }

  public getStartPrice(): number {
    let startPrice: number;

    if (this.product.getUsedNetworkPrice()) {
      if (this.product.getUsedNetworkNetPrice()) {
        startPrice = this.product.getGrossPrice() / this.product.getAuthorizedQuantity();
      } else if (this.product.getUsedNetworkGrossPrice()) {
        startPrice = this.product.getGrossPrice() / this.product.getAuthorizedQuantity();
      } else {
        startPrice = this.product.getPmcPrice();
      }
    } else {
      startPrice = this.product.getPmcPrice();
    }

    return startPrice;
  }

  public calculateDiscount(): number {
    let discount = this.product.getDiscount();
    //let authorizedQuantity: number;

    if (this.product.getHasExternalAuthorizerBetterDiscount()) {
      discount = this.product.getExternalAuthorizerDiscount();
    }

    // if (this.product.getAuthorizedQuantity() > 0) {
    //   authorizedQuantity = this.product.getAuthorizedQuantity();
    // } else {
    //   authorizedQuantity = this.productQuantity;
    // }

    for (let progressiveDicount of this.product.getProgressiveDiscountList()) {
      if (progressiveDicount.getProductQuantity() === this.product.getAuthorizedQuantity() && progressiveDicount.getDiscount() > discount) {
        discount = progressiveDicount.getDiscount();
      }
    }
    return discount;
  }

  public calculatePrice(): number {
    // let calculatedPrice: number;

    // if (this.product.getUsedNetworkPrice()) {
    //   if (this.product.getUsedNetworkGrossPrice()) {
    //     calculatedPrice = (this.product.getNetworkPrice() * (100 - this.calculateDiscount())) / 100;
    //   } else if (this.product.getUsedNetworkNetPrice()) {
    //     calculatedPrice = this.product.getNetPrice();
    //   }
    // } else {
    //   calculatedPrice = (this.product.getPmcPrice() * (100 - this.calculateDiscount())) / 100;
    // }

    return this.product.getPrice();
  }

  public hasMedicalPrescriptionInitialized(): boolean {
    return (
      !!this.getMedicalPrescription().getPractitionerRegistration().getState().getValue() ||
      !!this.getMedicalPrescription().getPractitionerRegistration().getRegistration() ||
      !!this.getMedicalPrescription().getMedicalPrescriptionDate()
    );
  }

  public copyFromResultItem(resultItem: SalesOrderItemResult): void {
    this.setErrorCauseList(resultItem.getErrorCauseList());
    this.getProduct().setGrossPrice(resultItem.getGrossPrice());
    this.getProduct().setUsedNetworkPrice(resultItem.getUsedNetworkPrice());
    this.getProduct().setUsedNetworkNetPrice(resultItem.getUsedNetworkNetPrice());
    this.getProduct().setUsedNetworkGrossPrice(resultItem.getUsedNetworkGrossPrice());
    this.getProduct().setDiscount(resultItem.getDiscount());
    this.getProduct().setAuthorizedQuantity(resultItem.getAuthorizedQuantity());
  }
}
