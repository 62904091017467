import { Injectable } from "@angular/core";
import { RemoteGatewayFactory } from "../../gateways/remote-gateway-factory";
import { ConsultSalesPagingRequest } from "../../models/domains/paginator/consult-sales-paging-request";
import { SalesOrderResult } from "../../models/domains/pdv/sales-order-result";
import { BackendUrl } from "../../models/domains/urls/back-end.url";
import { SalesOrderParser } from "../parsers/pdv/sales-order.parser";

@Injectable()
export class SalesRepository {
  constructor(private remoteGatewayFactory: RemoteGatewayFactory, private salesOrderParser: SalesOrderParser) {}

  public async searchSales(consultSalesPagingRequest: ConsultSalesPagingRequest): Promise<any> {
    const body = JSON.stringify(consultSalesPagingRequest);
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      const remoteGateway = this.remoteGatewayFactory.createDefaultRemoteGateway();
      const payload: any = await remoteGateway.postWithHeaders(new BackendUrl("/api/pbm/sales-order/ForReturnWithFilter"), body, headers);

      return await Promise.resolve(this.salesOrderParser.parseList(payload.Data));
    } catch (referenceError) {
      console.error("repositório searchSales", referenceError);
      let errorMessage = "";
      if (referenceError.error && referenceError.error.Message) {
        errorMessage = referenceError.error.Message;
      } else {
        errorMessage = "Erro ao buscar informações no servidor.";
      }
      return await Promise.reject(errorMessage);
    }
  }

  public async refundSale(salesOrderResult: SalesOrderResult): Promise<any> {
    const body = {
      uniqueSequentialNumber: salesOrderResult.getUniqueSequentialNumber(),
    };
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      const remoteGateway = this.remoteGatewayFactory.createDefaultRemoteGateway();
      const payload = await remoteGateway.postWithHeaders(new BackendUrl("/api/pbm/sales-order/refund"), body, headers);
      return await Promise.resolve(this.salesOrderParser.parseRefund(payload));
    } catch (referenceError) {
      console.error("Erro no repositório", referenceError);
      const errorMessage = referenceError.error.Data.ErrorMessage;
      return await Promise.reject(errorMessage);
    }
  }
}
