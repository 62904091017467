import * as moment from "moment";

export class PdvCycleItem {
  constructor(
    private readonly referenceMonth: number,
    private readonly startPeriod: string,
    private readonly endPeriod: string,
    private readonly paymentDate: string,
    private readonly totalPaymentValue: number,
    private readonly totalCardValue: number,
    private readonly totalCheckoutValue: number,
    private readonly totalAdministrativeTax: number,
  ) {
    moment.locale("pt-BR");
  }

  public getReferenceMonth(): number {
    return this.referenceMonth;
  }

  public getStartPeriod(): string {
    return this.startPeriod;
  }

  public getEndPeriod(): string {
    return this.endPeriod;
  }

  public getPaymentDate(): string {
    return this.paymentDate;
  }

  public getTotalCardValue(): number {
    return this.totalCardValue;
  }

  public getTotalCheckoutValue(): number {
    return this.totalCheckoutValue;
  }

  public getTotalAdministrativeTax(): number {
    return this.totalAdministrativeTax;
  }

  public getTotalPaymentValue(): number {
    return this.totalPaymentValue;
  }

  public getReferenceMonthName(): string {
    return moment.months(this.referenceMonth - 1);
  }
}
