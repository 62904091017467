import { Component, OnInit } from "@angular/core";
import { ErrorCause } from "src/app/ui/models/domains/pdv/error-cause";
import { SalesOrder } from "src/app/ui/models/domains/pdv/sales-order";
import { SalesOrderItemResult } from "src/app/ui/models/domains/pdv/sales-order-item-result";
import { SalesOrderResult } from "src/app/ui/models/domains/pdv/sales-order-result";
import { HomeService } from "src/app/ui/services/pdv/home.service";
import { PurchaseService } from "src/app/ui/services/pdv/purchase.service";
import { RuleEngineConfigurationService } from "src/app/ui/services/pdv/rule-engine-configuration.service";

@Component({
  selector: "pdv-pre-sales-content",
  templateUrl: "pre-sales-content.component.html",
  styleUrls: ["pre-sales-content.component.scss"],
})
export class PreSalesContentComponent implements OnInit {
  salesOrder: SalesOrder;
  salesOrderResult: SalesOrderResult;
  preSalesValidityHours: number;

  constructor(private purchaseService: PurchaseService, private homeService: HomeService, private ruleEngineConfigurationService: RuleEngineConfigurationService) {}

  async ngOnInit() {
    this.purchaseService.getSalesOrder().subscribe((salesOrder) => (this.salesOrder = salesOrder));
    this.purchaseService.getSalesOrderResult().subscribe((salesOrderResult) => (this.salesOrderResult = salesOrderResult));
    this.preSalesValidityHours = await this.ruleEngineConfigurationService.getPreSalesValidityHours(this.salesOrder.getShopper());
  }

  newPurchase() {
    this.purchaseService.newPurchase();
    this.homeService.initializePurchase();
  }

  endAttendance() {
    this.purchaseService.endAttendance();
    this.homeService.standByStage();
  }

  salesOrderItemResultList(): SalesOrderItemResult[] {
    if (!!this.salesOrderResult) {
      return this.salesOrderResult.getSalesOrderItemResultList();
    }
    return [];
  }

  salesOrderResultHasErrorCauses(): boolean {
    return !!this.salesOrderResult && this.salesOrderResult.getErrorCauseList().length > 0;
  }

  salesOrderResultErrorCauseList(): ErrorCause[] {
    return this.salesOrderResult.getErrorCauseList();
  }

  getItemResultStatus(salesOrderItemResult: SalesOrderItemResult): string {
    if (salesOrderItemResult.getErrorCauseList().length > 0) {
      const errorMessageList: string[] = [];
      salesOrderItemResult.getErrorCauseList().map((error) => {
        errorMessageList.push(error.getErrorMessage());
      });
      return errorMessageList.join(" - ");
    }
    return "AUTORIZADO";
  }
}
