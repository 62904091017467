import { Ean } from "src/app/ui/models/domains/pdv/ean";
import { ErrorCause } from "src/app/ui/models/domains/pdv/error-cause";
import { FiscalNumber } from "src/app/ui/models/domains/pdv/fiscal-number";
import { MedicalPrescription } from "src/app/ui/models/domains/pdv/medical-prescription";
import { Network } from "src/app/ui/models/domains/pdv/network";
import { ProductResult } from "src/app/ui/models/domains/pdv/product-result";
import { SalesOrderItemResult } from "src/app/ui/models/domains/pdv/sales-order-item-result";
import { SalesOrderResult } from "src/app/ui/models/domains/pdv/sales-order-result";
import { Shopper } from "src/app/ui/models/domains/pdv/shopper";
import { SocialNumber } from "src/app/ui/models/domains/pdv/social-number";
import { TaxCoupon } from "src/app/ui/models/domains/pdv/tax-coupon";
import { UniqueSequentialNumber } from "src/app/ui/models/domains/pdv/unique-sequential-number";
import { AbstractParser } from "../parser";

export class SalesOrderParser extends AbstractParser<SalesOrderResult> {
  parse(payload: any) {
    const salesOrderResult = new SalesOrderResult();
    salesOrderResult.setUniqueSequentialNumber(this.parseUniqueSequentialNumber(payload.UniqueSequentialNumber));
    salesOrderResult.setShopper(this.parseShopper(payload.Beneficiary));
    salesOrderResult.setTransactionDate(new Date(payload.TransactionDate));
    salesOrderResult.setSalesAction(payload.SalesAction ? payload.SalesAction : null);
    salesOrderResult.setNetwork(this.parseNetwrok(payload.NetworkInfo));
    salesOrderResult.setSalesOrderItemResultList(this.parseSalesOrderItemResultList(payload.Items ? payload.Items : null));
    salesOrderResult.setErrorCauseList(this.parseErrorCauseList(payload.ErrorCauses));
    salesOrderResult.setTaxCoupon(this.parseTaxCoupon(payload.TaxCoupon));
    salesOrderResult.setTotalDiscountValue(payload.TotalDiscountValue ? payload.TotalDiscountValue : null);
    salesOrderResult.setTotalGrossPrice(payload.TotalGrossPrice ? payload.TotalGrossPrice : null);
    salesOrderResult.setTotalPrice(payload.TotalPrice ? payload.TotalPrice : null);
    salesOrderResult.setTotalSubsidyValue(payload.TotalSubsidyValue ? payload.TotalSubsidyValue : null);
    salesOrderResult.setTotalEmploymentPayrollValue(payload.TotalEmploymentPayrollValue ? payload.TotalEmploymentPayrollValue : null);
    salesOrderResult.setTotalCheckoutValue(payload.TotalCheckoutValue ? payload.TotalCheckoutValue : null);
    return salesOrderResult;
  }

  private parseUniqueSequentialNumber(payload: any): UniqueSequentialNumber {
    const uniqueSequentialNumber = new UniqueSequentialNumber();
    uniqueSequentialNumber.setValue(payload ? payload.Value : null);
    return uniqueSequentialNumber;
  }

  private parseShopper(payload: any): Shopper {
    const shopper = new Shopper();
    if (payload) {
      shopper.setSocialNumber(new SocialNumber(payload ? payload.SocialNumber.Value : null));
      shopper.setVirtualCardNumber(payload.Card.CardNumber);
    }
    return shopper;
  }

  private parseNetwrok(payload: any): Network {
    const network = new Network();
    if (payload) {
      network.setCorporateName(payload ? payload.Name : null);
      network.setFiscalNumber(new FiscalNumber(payload ? payload.FiscalNumber : null));
    }
    return network;
  }

  private parseSalesOrderItemResultList(payload: any): SalesOrderItemResult[] {
    if (payload) {
      const salesOrderItemResultList: SalesOrderItemResult[] = payload.map((item) => {
        const salesOrderItemResult = new SalesOrderItemResult(this.parseProductResult(item.Product));
        salesOrderItemResult.setAuthorizedQuantity(item.AuthorizedQuantity);
        salesOrderItemResult.setProductQuantity(item.ProductQuantity);
        salesOrderItemResult.setHasMedicalPrescription(item.HasMedicalPrescription);
        salesOrderItemResult.setPaymentMethod(item.PaymentMethod);
        salesOrderItemResult.setPaymentMethodDescription(item.PaymentMethodDescription);
        salesOrderItemResult.setPrice(item.Price);
        salesOrderItemResult.setUnitPrice(item.UnitPrice);
        salesOrderItemResult.setMdmPrice(item.MdmPrice);
        salesOrderItemResult.setGrossPrice(item.GrossPrice);
        salesOrderItemResult.setDiscount(item.Discount);
        salesOrderItemResult.setDiscountValue(item.DiscountValue);
        salesOrderItemResult.setNetworkPrice(item.NetworkPrice);
        salesOrderItemResult.setSubsidyDiscount(item.SubsidyDiscount);
        salesOrderItemResult.setSubsidyValue(item.SubsidyValue);
        salesOrderItemResult.setCardValue(item.CardValue);
        salesOrderItemResult.setCheckoutValue(item.CheckoutValue);
        salesOrderItemResult.setPresentation(item.Presentation || "");
        salesOrderItemResult.setEmploymentPayrollValue(item.EmploymentPayrollValue);
        salesOrderItemResult.setErrorCauseList(this.parseErrorCauseList(item.ErrorCauses));
        salesOrderItemResult.setMedicalPrescription(this.parseMedicalPrescription(item.MedicalPrescription));
        salesOrderItemResult.setUsedAttendanceDiscount(item.UsedAttendanceDiscount);
        salesOrderItemResult.setUsedNetworkPrice(item.UsedNetworkPrice);
        salesOrderItemResult.setUsedNetworkGrossPrice(item.UsedNetworkGrossPrice);
        salesOrderItemResult.setUsedNetworkNetPrice(item.UsedNetworkNetPrice);
        salesOrderItemResult.setUsedNetworkNetPrice(item.UsedNetworkNetPrice);

        return salesOrderItemResult;
      });
      return salesOrderItemResultList;
    }
    return [];
  }

  private parseProductResult(payload: any): ProductResult {
    const productResult = new ProductResult();
    if (payload) {
      productResult.setName(payload.Name);
      productResult.setEan(new Ean(payload.Ean));
      productResult.setLaboratory(payload.Laboratory);
      productResult.setPresentation(payload.Presentation);
      productResult.setSubstance(payload.Substance);
      productResult.setType(payload.Type);
      productResult.setSubType(payload.SubType);
    }
    return productResult;
  }

  private parseErrorCauseList(payload: any): ErrorCause[] {
    if (payload) {
      const errorCauseList: ErrorCause[] = payload.map((item) => {
        const errorCause = new ErrorCause();
        errorCause.setErrorCode(item.ErrorCode);
        errorCause.setErrorException(item.ErroException);
        errorCause.setErrorMessage(item.ErrorMessage);
        return errorCause;
      });
      return errorCauseList;
    }
    return [];
  }

  private parseMedicalPrescription(payload: any): MedicalPrescription {
    if (payload) {
      const medicalPrescription = new MedicalPrescription();
      medicalPrescription.setQuantity(payload.Quantity);
      medicalPrescription.setMedicalPrescriptionDate(payload.MedicalPrescriptionDate);
      medicalPrescription.setPractitionerRegistration(payload.PractitionerRegistration);
      medicalPrescription.setMedicalPrescriptionDocumentKey(payload.MedicalPrescriptionDocumentKey);
      return medicalPrescription;
    }
    return null;
  }

  private parseTaxCoupon(payload: any): TaxCoupon {
    if (payload) {
      const taxCoupon = new TaxCoupon();
      taxCoupon.setConsumerReceipt(payload.ConsumerReceipt);
      taxCoupon.setNetworkReceipt(payload.NetworkReceipt);
      return taxCoupon;
    }
    return null;
  }

  public parseRefund(payload: any): UniqueSequentialNumber {
    const uniqueSequentialNumber = new UniqueSequentialNumber();
    if (payload) {
      uniqueSequentialNumber.setValue(payload.UniqueSequentialNumber.Value);
    }
    return uniqueSequentialNumber;
  }
}
