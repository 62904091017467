import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material";
import { SelectorCompanyBenefitDialog } from "../../components/pdv/beneficiary-header/selector-company-benefit-dialog/selector-company-benefit-dialog.component";
import { Toast } from "../../components/toast/toast";
import { ProductSubtype } from "../../models/domains/pdv/Benefit/product-subtype";
import { Card } from "../../models/domains/pdv/card";
import { Shopper } from "../../models/domains/pdv/shopper";
import { SocialNumber } from "../../models/domains/pdv/social-number";
import { BenefitRepository } from "../../repositories/pdv/benefit.repository";
import { ProductRepository } from "../../repositories/pdv/product.repository";
import { ShopperRepository } from "../../repositories/pdv/shopper.repository";
import { LoadingService } from "../loading.service";
import { HomeService } from "./home.service";
import { PurchaseService } from "./purchase.service";

@Injectable({ providedIn: "root" })
export class ShopperService {
  constructor(
    private shopperRepository: ShopperRepository,
    private benefitRepository: BenefitRepository,
    private productRepository: ProductRepository,
    private toast: Toast,
    private loadingService: LoadingService,
    private purchaseService: PurchaseService,
    private homeService: HomeService,
    private dialog: MatDialog,
  ) {}

  /**
   *
   * @param inputData CPF ou Cartão do Beneficiário
   */
  async searchBeneficiary(inputData: string): Promise<void> {
    let ref = this.loadingService.beginLoading();
    try {
      if (inputData.length == 17) {
        const card = new Card();
        card.setCardNumber(inputData);
        const benefit = await this.shopperRepository.searchShopperBenefitByCardNumber(card);
        if (benefit.getIsBlockedCard()) {
          this.toast.showWarningToast("Cartão bloqueado ou cancelado.");
        } else {
          await this.processBenefit(benefit);
        }
      } else if (inputData.length == 11) {
        const socialNumber = new SocialNumber(inputData);
        const shopperBenefits = await this.shopperRepository.searchShopperBnefitsBySocialNumber(socialNumber);

        if (shopperBenefits.length > 1) {
          this.dialog
            .open(SelectorCompanyBenefitDialog, {
              data: shopperBenefits.sort((a, b) => Number(a.getIsBlockedCard()) - Number(b.getIsBlockedCard())),
            })
            .afterClosed()
            .subscribe(async (selectedBenefit) => {
              if (selectedBenefit) {
                await this.processBenefit(selectedBenefit as Shopper);
              }
            });
        } else if (shopperBenefits.length == 1) {
          if (shopperBenefits[0].getIsBlockedCard()) {
            this.toast.showWarningToast("Cartão bloqueado ou cancelado.");
          } else {
            await this.processBenefit(shopperBenefits[0]);
          }
        } else {
          this.toast.showWarningToast("Nenhum benefício encontrado.");
        }
      } else {
        this.toast.showWarningToast("Número do cartão ou CPF inválido.");
      }
    } catch (error) {
      console.error("search:", error);
      let errorMessage = "";
      if (error.status == 404) {
        errorMessage = "Beneficiário não encontrado.";
      } else if (error.error.Data.ErrorMessage === "Benefit.Configuration.Transaction.Find.Error") {
        errorMessage = "Beneficiário sem configuração de benefício.";
      } else {
        errorMessage = error.error.Data.ErrorMessage;
      }
      this.toast.showWarningToast(errorMessage || "Tivemos um problema no processamento.");
    } finally {
      inputData = "";
      this.loadingService.finishLoading(ref);
      console.log("terminou searchBeneficiary");
    }
  }

  private async processBenefit(benefit: Shopper): Promise<void> {
    await this.loadShopperTransactionSettings(benefit)
      .then((result) => {
        this.purchaseService.addShopper(result);
        this.homeService.initializePurchase();
      })
      .catch((fault) => {
        console.error(fault);
        let message = "";
        if (fault && fault.error && fault.error.Data) {
          message = fault.error.Data.ErrorMessage;
        }
        this.toast.showWarningToast(message || "Tivemos um problema no processamento.");
      });
  }

  public async loadShopperTransactionSettings(shopper: Shopper): Promise<Shopper> {
    try {
      const card = new Card();
      card.setCardNumber(shopper.getVirtualCardNumber());

      const financialStatement = await this.shopperRepository.searchFinancialBalance(card);
      const benefitConfiguration = await this.benefitRepository.findBenefitConfiguration(card);
      const productConfiguration = await this.benefitRepository.findProductConfiguration(card);

      for (const productConfig of productConfiguration.getProductConfiguration()) {
        const productSubtypeList = await this.findProductSubtypeList(productConfig.getProductType().getKey());
        const index = productSubtypeList.findIndex((el) => productConfig.getProductSubType().getKey() === el.getKey());
        if (index !== -1) {
          productConfig.setProductSubType(productSubtypeList[index]);
        }
      }
      benefitConfiguration.setProductConfiguration(productConfiguration);
      shopper.setFinancialBalance(financialStatement);
      shopper.setBenefitConfiguration(benefitConfiguration);
      return shopper;
    } catch (error) {
      console.error("loadShopperTransactionSettings", error);
      return await Promise.reject(error);
    }
  }

  private async findProductSubtypeList(productTypeKey: string) {
    return await this.productRepository.getProductSubtypeByType(productTypeKey);
  }

  private async getProductSubtypeByType(typeKey: string): Promise<Array<ProductSubtype>> {
    return await this.productRepository.getProductSubtypeByType(typeKey);
  }
}
