import { Injectable } from "@angular/core";
import { RemoteGatewayFactory } from "../../gateways/remote-gateway-factory";
import { ExportOrderKey } from "../../models/domains/pdv/exports/export-order-key";
import { CSVFileExportResult, XLSXFileExportResult } from "../../models/domains/pdv/exports/file-export-result";
import { BackendUrl } from "../../models/domains/urls/back-end.url";
import { ExportFileAsXLSXParser } from "../parsers/pdv/export-file-as-xlsx.parser";
import { ExportFileParser } from "../parsers/pdv/export-file.parser";
import { GetFileExportStatusParser } from "../parsers/pdv/get-file-export-status.parser";

@Injectable()
export class FileExportRepository {
  constructor(
    private remoteGatewayFactory: RemoteGatewayFactory,
    private getFileExportStatusParser: GetFileExportStatusParser,
    private exportFileParser: ExportFileParser,
    private exportFileAsXLSXParser: ExportFileAsXLSXParser,
  ) {}

  public async exportFileAsCSV(exportOrderKey: ExportOrderKey): Promise<CSVFileExportResult> {
    const headers = { "Content-Type": "application/json" };

    try {
      var data = await this.remoteGatewayFactory.createDefaultRemoteGateway().downloadAsGet(new BackendUrl("/api/pbm/export/" + exportOrderKey.getValue()), headers);
      return this.exportFileParser.parse(data);
    } catch (referenceError) {
      const errorMessage = referenceError.error.Data.ErrorMessage;
      return await Promise.reject(errorMessage);
    }
  }

  public async exportFileAsXLSX(exportOrderKey: ExportOrderKey): Promise<XLSXFileExportResult> {
    const headers = { "Content-Type": "application/json" };

    try {
      var data = await this.remoteGatewayFactory.createDefaultRemoteGateway().downloadAsGet(new BackendUrl("/api/pbm/export/" + exportOrderKey.getValue()), headers);
      return this.exportFileAsXLSXParser.parse(data);
    } catch (referenceError) {
      const errorMessage = referenceError.error.Data.ErrorMessage;
      return await Promise.reject(errorMessage);
    }
  }

  public async getFileExportStatus(exportOrderKey: ExportOrderKey): Promise<boolean> {
    const headers = { "Content-Type": "application/json" };

    try {
      const data = await this.remoteGatewayFactory.createDefaultRemoteGateway().getWithHeaders(new BackendUrl("/api/pbm/export/status/" + exportOrderKey.getValue()), headers);
      return this.getFileExportStatusParser.parse(data);
    } catch (referenceError) {
      const errorMessage = referenceError.error.Data.ErrorMessage;
      return await Promise.reject(errorMessage);
    }
  }

  /**
   * Mesma lógica do getFileExportStatus porém consegue retornar se deu algum erro no processamento do arquivo
   * @param exportOrderKey Key do arquivo a ser verificado
   * @returns 0=não está pronto; 1=pronto; -1=erro
   */
  public async getFileExportStatusNumber(exportOrderKey: ExportOrderKey): Promise<number> {
    const headers = { "Content-Type": "application/json" };
    try {
      const data = await this.remoteGatewayFactory.createDefaultRemoteGateway().getWithHeaders(new BackendUrl("/api/pbm/export/status/" + exportOrderKey.getValue()), headers);
      return this.getFileExportStatusParser.parseNumber(data);
    } catch (referenceError) {
      const errorMessage = referenceError.error.Data.ErrorMessage;
      return await Promise.reject(errorMessage);
    }
  }
}
