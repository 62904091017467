import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";

import { SalesOrder } from "src/app/ui/models/domains/pdv/sales-order";
import { Shopper } from "src/app/ui/models/domains/pdv/shopper";
import { PurchaseService } from "src/app/ui/services/pdv/purchase.service";

@Component({
  selector: "pdv-beneficiary-summary",
  templateUrl: "./beneficiary-summary.component.html",
  styleUrls: ["./beneficiary-summary.component.scss"],
})
export class BeneficiarySummaryComponent implements OnInit {
  private salesOrder$: Observable<SalesOrder>;
  shopper: Shopper;
  openBeneficiaryRules: boolean;

  constructor(private purchaseService: PurchaseService) {}

  ngOnInit(): void {
    this.salesOrder$ = this.purchaseService.getSalesOrder();
    this.salesOrder$.subscribe((salesOrder) => {
      if (salesOrder) {
        this.shopper = salesOrder.getShopper();
      }
    });
  }

  hasShopper(): boolean {
    return this.shopper ? true : false;
  }

  showBeneficiaryRules(): void {
    this.openBeneficiaryRules = true;
  }

  hideBeneficiaryRules(): void {
    this.openBeneficiaryRules = false;
  }

  isBlockedCard(): boolean {
    if (!this.hasShopper()) {
      return false;
    }
    return this.shopper.getIsBlockedCard();
  }

  getShopperFinancialBalanceView(): string {
    if (this.hasShopper()) {
      return this.shopper.getFinancialBalance().getFinancialBalanceView();
    }
    return "--";
  }
}
