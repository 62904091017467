import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";

import { SalesOrder } from "src/app/ui/models/domains/pdv/sales-order";
import { SalesOrderItem } from "src/app/ui/models/domains/pdv/sales-order-item";
import { SalesOrderResult } from "src/app/ui/models/domains/pdv/sales-order-result";
import { LoadingService } from "src/app/ui/services/loading.service";
import { HomeService } from "src/app/ui/services/pdv/home.service";
import { PurchaseService } from "src/app/ui/services/pdv/purchase.service";
import { Toast } from "../../../toast/toast";

@Component({
  selector: "pdv-product-basket",
  templateUrl: "./product-basket.component.html",
  styleUrls: ["./product-basket.component.scss"],
})
export class ProductBasketComponent implements OnInit {
  salesOrder$: Observable<SalesOrder>;
  salesOrderItemList: Array<SalesOrderItem>;

  constructor(private purchaseService: PurchaseService, private homeService: HomeService, private loadingService: LoadingService, private toast: Toast) {}

  ngOnInit(): void {
    this.salesOrder$ = this.purchaseService.getSalesOrder();
    this.salesOrder$.subscribe((salesOrder) => {
      if (salesOrder) {
        this.salesOrderItemList = salesOrder.getSalesOrderItemList();
      }
    });
  }

  hasSalesOrderItem(): boolean {
    return this.salesOrderItemList.length > 0 ? true : false;
  }

  public async emitPreviewPreSalesOrder(): Promise<void> {
    const ref = this.loadingService.beginLoading();
    try {
      const salesOrderResult = await this.purchaseService.emitPreviewPreSalesOrder();
      if (salesOrderResult.getErrorCauseList().length > 0) {
        const errorMessage = salesOrderResult.getErrorCauseList()[0].getErrorMessage();
        this.toast.showWarningToast(errorMessage);
      } else {
        this.homeService.previewPurchase();
      }
    } catch (erroMessage) {
      this.toast.showWarningToast(erroMessage);
    } finally {
      this.loadingService.finishLoading(ref);
    }
  }
}
