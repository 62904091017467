import { Injectable } from "@angular/core";

import { UserCredentials } from "../models/domains/credentials/user-credentials.model";
import { AbstractParser } from "../repositories/parsers/parser";
import { environment } from "../../../environments/environment";
import { ServiceCredentials } from "../models/domains/credentials/service-credentials.model";
import { ReleaseNote } from "../models/domains/news/release-note.model";
import { ReleaseNoteEntity, ReleaseNoteItemEntity } from "../models/domains/news/release-note-entity.model";
import { ReleaseNoteItem } from "../models/domains/news/release-note-item.model";
import { PdvPassport, UnkownPdvPassport } from "../models/domains/pdv/pdv-passport";
import { Network, UnknowNetwork } from "../models/domains/pdv/network";
import { SalesOrder } from "../models/domains/pdv/sales-order";
import { NetworkFactory } from "../models/domains/pdv/network-factory";
import { PdvPassportFactory } from "../models/domains/pdv/pdv-passport-factory";
import { SalesOrderFactory } from "../models/domains/pdv/sales-order-factory";
import { SalesOrderResult } from "../models/domains/pdv/sales-order-result";
import { Shopper } from "../models/domains/pdv/shopper";

const PDV_PASSPORT = "pdv-passport";
const NETWORK = "network";
const SALES_ORDER = "sales-order";
const SALES_ORDER_RESULT = "sales-order-result";
const USER_CREDENTIAL_KEY = `user-credential-${environment.appName}`;
const SERVICE_CREDENTIAL_KEY = "service-credential";
const RELEASE_NOTE_KEY = "releaseNote";

@Injectable()
export class AppContext {
  private pdvPassport: StorageEntry<PdvPassport>;
  private network: StorageEntry<Network>;
  private salesOrder: StorageEntry<SalesOrder>;
  private salesOrderResult: StorageEntry<SalesOrderResult>;
  private userCredential: StorageEntry<UserCredentials>;
  private serviceCredential: StorageEntry<ServiceCredentials>;
  private lastReleaseNoteStoreage: StorageEntry<ReleaseNoteEntity>;

  constructor() {
    this.pdvPassport = new SessionStorageEntry<PdvPassport>(PDV_PASSPORT);
    this.network = new SessionStorageEntry<Network>(NETWORK);
    this.salesOrder = new SessionStorageEntry<SalesOrder>(SALES_ORDER);
    this.salesOrderResult = new SessionStorageEntry<SalesOrderResult>(SALES_ORDER_RESULT);
    this.userCredential = new LocalStorageEntry<UserCredentials>(USER_CREDENTIAL_KEY);
    this.serviceCredential = new SessionStorageEntry<ServiceCredentials>(SERVICE_CREDENTIAL_KEY);
    this.lastReleaseNoteStoreage = new LocalStorageEntry<ReleaseNoteEntity>(RELEASE_NOTE_KEY);
  }

  public cleanAppContext() {
    localStorage.clear();
  }

  public setPdvPassport(pdvPassport: PdvPassport): void {
    this.pdvPassport.set(pdvPassport);
  }

  public getPdvPassport(): PdvPassport {
    const pdvPassport = this.pdvPassport.get();
    if (!!pdvPassport) {
      return PdvPassportFactory.createPdvPassport(pdvPassport);
    }
    return new UnkownPdvPassport();
  }

  public cleanPdvPassport(): void {
    this.pdvPassport.remove();
  }

  public setNetwork(network: Network): void {
    this.network.set(network);
  }

  public getNetwork(): Network {
    const network = this.network.get();
    if (!!network) {
      return NetworkFactory.createNetwork(network);
    }
    return new UnknowNetwork();
  }

  public cleanNetwork(): void {
    this.network.remove();
  }

  public setSalesOrder(value: SalesOrder): void {
    this.salesOrder.set(value);
  }

  public getSalesOrder(): SalesOrder {
    const salesOrder = this.salesOrder.get();
    return SalesOrderFactory.createSalesOrder(salesOrder);
  }

  public cleanSalesOrder(): void {
    this.salesOrder.remove();
  }

  public setSalesOrderResult(value: SalesOrderResult): void {
    this.salesOrderResult.set(value);
  }

  public getSalesOrderResult(): SalesOrderResult {
    return this.salesOrderResult.get();
  }

  public cleanSalesOrderResult(): void {
    this.salesOrderResult.remove();
  }

  public getBackendUrl(): string {
    return environment.backendUrl;
  }

  public setLastReleaseNote(releaseNote: ReleaseNote): void {
    const entity = new ReleaseNoteEntity();
    entity.name = releaseNote.getName();
    entity.items = releaseNote.getItems().map((i) => {
      let item = new ReleaseNoteItemEntity();
      item.description = i.getDescription();
      item.releaseNoteType = i.getType();
      item.title = i.getTitle();
      return item;
    });
    this.lastReleaseNoteStoreage.set(entity);
  }

  public getLastReleaseNote(): ReleaseNote | null {
    const entity = this.lastReleaseNoteStoreage.get();
    if (entity) {
      let items: Array<ReleaseNoteItem> = [];
      if (entity.items) {
        entity.items.forEach((item) => {
          items.push(new ReleaseNoteItem(item.title, item.description, item.releaseNoteType));
        });
      }
      return new ReleaseNote(entity.name, items);
    }
    return null;
  }

  public setUserCredential(credential: UserCredentials) {
    this.userCredential.set(credential);
  }

  public setServiceCredential(credential: ServiceCredentials) {
    this.serviceCredential.set(credential);
  }

  public getServiceCredential(): ServiceCredentials | null {
    return this.serviceCredential.get();
  }

  public getUserCredential(): UserCredentials | null {
    return this.userCredential.get();
  }

  public clearUserCredential() {
    this.userCredential.remove();
  }

  public clearServiceCredential() {
    this.serviceCredential.remove();
  }
}

abstract class StorageEntry<T> {
  protected constructor(protected key: string, protected parser: AbstractParser<T> | any = null, private storage: Storage) {}

  protected parse(entity): T | null {
    return this.parser.parse(entity);
  }

  get(): T | null {
    const entity = this.storage.getItem(this.key);
    if (entity) {
      const entityJson = JSON.parse(entity);
      if (this.parser) {
        return Array.isArray(entityJson) ? this.parser.parseList(entityJson) : this.parser.parse(entityJson);
      }
      return entityJson;
    }
    return null;
  }

  set(item: T) {
    this.storage.setItem(this.key, JSON.stringify(item));
  }

  remove() {
    this.storage.removeItem(this.key);
  }
}

class LocalStorageEntry<T> extends StorageEntry<T> {
  constructor(protected key: string, protected parser: AbstractParser<T> | any = null) {
    super(key, parser, localStorage);
  }
}

class SessionStorageEntry<T> extends StorageEntry<T> {
  constructor(protected key: string, protected parser: AbstractParser<T> | any = null) {
    super(key, parser, sessionStorage);
  }
}
