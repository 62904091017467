import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material";

import { MedicalPrescriptionViewItem } from "src/app/ui/models/domains/pdv/medical-prescription-view-item";
import { Shopper } from "src/app/ui/models/domains/pdv/shopper";
import { SocialNumber } from "src/app/ui/models/domains/pdv/social-number";
import { LoadingService } from "src/app/ui/services/loading.service";
import { MedicalPrescriptionService } from "src/app/ui/services/pdv/medical-prescription.service";
import { PurchaseService } from "src/app/ui/services/pdv/purchase.service";

@Component({
  templateUrl: "./medical-prescription-dialog.component.html",
  styleUrls: ["./medical-prescription-dialog.component.scss"],
})
export class MedicalPrescriptionDialogComponent implements OnInit {
  private shopper: Shopper;
  //private purchaseService.getMedicalPrescriptionViewItemList(): MedicalPrescriptionViewItem[] = [];

  constructor(
    public dialogRef: MatDialogRef<any>,
    private purchaseService: PurchaseService,
    private medicalPrescriptionService: MedicalPrescriptionService,
    private loadingService: LoadingService,
  ) {}

  ngOnInit(): void {
    const sub = this.purchaseService.getSalesOrder().subscribe((salesOrder) => {
      this.shopper = salesOrder.getShopper();

      for (const salesOrderItem of salesOrder.getSalesOrderItemList()) {
        const medicalPrescriptionViewItem = this.purchaseService
          .getMedicalPrescriptionViewItemList()
          .find((item) => item.getEan().getValue() === salesOrderItem.getProduct().getEan().getValue());

        /**
         * Se o item já existe, apenas atualiza os dados da receita. Pode ser que o usuário volte a tela e mude algo.
         */
        if (!!medicalPrescriptionViewItem) {
          medicalPrescriptionViewItem.setProductName(salesOrderItem.getProduct().getName() + salesOrderItem.getProduct().getPresentation());
          medicalPrescriptionViewItem.setPratitionerRegistrationState(salesOrderItem.getMedicalPrescription().getPractitionerRegistration().getState());
          medicalPrescriptionViewItem.setPratitionerRegistration(salesOrderItem.getMedicalPrescription().getPractitionerRegistration().getRegistration());
          medicalPrescriptionViewItem.setMedicalPrescriptionDate(salesOrderItem.getMedicalPrescription().getMedicalPrescriptionDate());
        } else {
          const newMedicalPrescriptionViewItem = new MedicalPrescriptionViewItem(
            salesOrderItem.getProduct().getEan(),
            salesOrderItem.getProduct().getName() + salesOrderItem.getProduct().getPresentation(),
            salesOrderItem.getMedicalPrescription().getPractitionerRegistration().getState(),
            salesOrderItem.getMedicalPrescription().getPractitionerRegistration().getRegistration(),
            salesOrderItem.getMedicalPrescription().getMedicalPrescriptionDate(),
          );
          this.purchaseService.getMedicalPrescriptionViewItemList().push(newMedicalPrescriptionViewItem);
        }
      }
      sub.unsubscribe();
    });
  }

  //if (!this.purchaseService.getMedicalPrescriptionViewItemList().some((item) => item.getEan().getValue() === salesOrderItem.getProduct().getEan().getValue()))

  viewItemList(): MedicalPrescriptionViewItem[] {
    return this.purchaseService.getMedicalPrescriptionViewItemList();
  }

  onSelectedAll() {
    this.purchaseService.getMedicalPrescriptionViewItemList().forEach((item) => {
      item.unCheckItem();
      item.setFile(null);
    });
  }

  isAllItemsSelected(): boolean {
    const allItemsInput = document.getElementById("all-items") as HTMLInputElement;
    return allItemsInput.checked;
  }

  hasAllItemsSelectedFile(): boolean {
    let hasFile = false;
    for (const item of this.purchaseService.getMedicalPrescriptionViewItemList()) {
      if (item.hasFile()) {
        hasFile = true;
      }
    }
    return hasFile;
  }

  onViewItemSelected(event: any, viewItem: MedicalPrescriptionViewItem): void {
    this.purchaseService.getMedicalPrescriptionViewItemList().forEach((item) => item.unCheckItem());
    event.target.checked ? viewItem.checkItem() : viewItem.unCheckItem();
  }

  hasViewItemSelected(): boolean {
    const item = this.purchaseService.getMedicalPrescriptionViewItemList().find((item) => item.isChecked());
    return !!item;
  }

  selectedViewItem(): MedicalPrescriptionViewItem {
    return this.purchaseService.getMedicalPrescriptionViewItemList().find((item) => item.isChecked());
  }

  handleFile(file: File) {
    if (file) {
      const reader = new FileReader();
      reader.onload = (event: any) => event.target.result;
      reader.readAsDataURL(file);
      if (this.isAllItemsSelected()) {
        this.purchaseService.getMedicalPrescriptionViewItemList().forEach((item) => item.setFile(file));
      } else {
        this.selectedViewItem().setFile(file);
      }
    }
  }

  getFileName(): string {
    if (this.isAllItemsSelected()) {
      if (this.hasAllItemsSelectedFile()) {
        return this.purchaseService.getMedicalPrescriptionViewItemList()[0].getFileName();
      }
      return "Clique aqui para selecionar o arquivo da prescrição médica";
    } else if (this.hasViewItemSelected()) {
      if (this.selectedViewItem().hasFile()) {
        return this.selectedViewItem().getFileName();
      }
      return "Clique aqui para selecionar o arquivo da prescrição médica";
    }
    return "Selecione um produto";
  }

  removeFile() {
    if (this.isAllItemsSelected()) {
      this.purchaseService.getMedicalPrescriptionViewItemList().forEach((item) => item.setFile(null));
    } else {
      this.selectedViewItem().setFile(null);
    }
  }

  async uploadFile() {
    const ref = this.loadingService.beginLoading();
    const uploadStatus = new UploadDocumentKeyStatus();
    try {
      for (const item of this.purchaseService.getMedicalPrescriptionViewItemList()) {
        if (item.hasFile()) {
          const formData = new FormData();
          formData.append("", item.getFile());
          const prescriptionKey = await this.medicalPrescriptionService.uploadMedicalPrescription(this.shopper, formData);
          item.setMedicalPrescriptionDocumentKey(prescriptionKey);
          this.purchaseService.updateMedicalPrescriptionDocumentKey(item);
          uploadStatus.documentKeyHasChanged();
        }
      }
    } catch (error) {
    } finally {
      this.loadingService.finishLoading(ref);
      this.closeDialog();
    }
  }

  selectedItemHasMedicalPrescriptionSetted(): boolean {
    return this.selectedViewItem().hasMedicalPrescriptionSetted();
  }

  allItemsHasMedicalPrescriptionSetted(): boolean {
    return !this.purchaseService.getMedicalPrescriptionViewItemList().some((item) => item.hasMedicalPrescriptionSetted() === false);
  }

  disableUploadFile(): boolean {
    return (this.hasViewItemSelected() && !this.selectedItemHasMedicalPrescriptionSetted()) || (this.isAllItemsSelected() && !this.allItemsHasMedicalPrescriptionSetted());
  }

  closeDialog() {
    this.dialogRef.close(true);
  }
}

class UploadDocumentKeyStatus {
  private documentKeyChanged = false;

  public documentKeyHasChanged(): void {
    this.documentKeyChanged = true;
  }

  public hasDocumentKeyChanged(): boolean {
    return this.documentKeyChanged;
  }
}
