import { Address } from "./address";
import { FiscalNumber } from "./fiscal-number";
import { Network } from "./network";
import { NetworkAcceptTerm } from "./network-accept-term";

export class NetworkFactory {
  static createNetwork(payload: any): Network {
    const network = new Network();
    if (payload) {
      network.setNetworkInfoKey(payload.networkInfoKey);
      network.setFiscalNumber(new FiscalNumber(payload.fiscalNumber.value));
      network.setCorporateName(payload.corporateName);
      network.setTradingName(payload.tradingName);
      network.setAddress(this.buildAddress(payload.address));
      if (payload.acceptTerm) {
        network.setAcceptedTerm(this.buildAcceptTerm(payload.acceptTerm));
      }
      network.setHeadOfficeExists(payload.headOfficeExists);
      network.setHeadOffice(payload.headOffice);
      network.setHeadOfficeAcceptedTerm(payload.headOfficeAcceptedTerm);
      return network;
    }
  }

  static buildAddress(payload: any): Address {
    const address = new Address();
    address.setStreet(payload.street);
    address.setNumber(payload.number);
    address.setComplement(payload.complement);
    address.setZipCode(payload.zipCode);
    address.setDistrict(payload.district);
    address.setCity(payload.city);
    address.setRegion(payload.region);
    return address;
  }

  static buildAcceptTerm(payload: any): NetworkAcceptTerm {
    return new NetworkAcceptTerm(payload.ReferenceKey, new Date(payload.AcceptTermDate));
  }
}
