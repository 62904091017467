import { Component, OnInit } from "@angular/core";

import { MaskInput } from "src/app/ui/models/domains/pdv/mask-input";
import { SalesOrder } from "src/app/ui/models/domains/pdv/sales-order";
import { SalesOrderItem } from "src/app/ui/models/domains/pdv/sales-order-item";
import { StateAcronymModel } from "src/app/ui/models/domains/pdv/state-acronym-model";
import { StateService } from "src/app/ui/services/pdv/state.service";
import { PurchaseService } from "src/app/ui/services/pdv/purchase.service";
import { NavigatorService } from "src/app/ui/services/pdv/navigator.service";

@Component({
  selector: "pdv-purchase-content",
  templateUrl: "./purchase-content.component.html",
  styleUrls: ["./purchase-content.component.scss"],
})
export class PurchaseContentComponent implements OnInit {
  salesOrder: SalesOrder;
  startPurchase: boolean;
  stateList: StateAcronymModel[] = [];
  dateMask = MaskInput.getDateMask();

  constructor(private purchaseService: PurchaseService, private stateService: StateService, private navigatorService: NavigatorService) {}

  async ngOnInit() {
    this.purchaseService.getSalesOrder().subscribe((salesOrder) => {
      this.salesOrder = salesOrder;
    });
    this.stateList = await this.stateService.getStates();
  }

  hasShopper(): boolean {
    if (this.salesOrder) {
      this.startPurchase = this.salesOrder.getShopper() ? true : false;
      return this.startPurchase;
    } else {
      return false;
    }
  }

  hasSalesOrderItem(): boolean {
    return this.salesOrder.getSalesOrderItemList().length > 0;
  }

  removeSalesOrderItem(salesOrderItem: SalesOrderItem): void {
    this.purchaseService.removeSalesOrderItem(salesOrderItem);
  }

  addSalesOrderItemQuantity(salesOrderItem: SalesOrderItem): void {
    this.purchaseService.addSalesOrderItemQuantity(salesOrderItem);
  }

  removeSalesOrderItemQuantity(salesOrderItem: SalesOrderItem): void {
    this.purchaseService.removeSalesOrderItemQuantity(salesOrderItem);
  }

  updateMedicalPrescription(salesOrderItem: SalesOrderItem): void {
    const practitioner_state = document.getElementById("practitioner_state_" + salesOrderItem.getKey()) as HTMLSelectElement;
    const practitioner_registration = document.getElementById("practitioner_registration_" + salesOrderItem.getKey()) as HTMLInputElement;
    const medical_prescription_date = document.getElementById("medical_prescription_date_" + salesOrderItem.getKey()) as HTMLInputElement;

    const stateInputValue = practitioner_state.value;
    const registrationInputValue = Number(practitioner_registration.value);
    const dateInputValue = medical_prescription_date.value;

    this.purchaseService.updateMedicalPrescription(salesOrderItem, stateInputValue, registrationInputValue, dateInputValue);
  }

  getState(salesOrderItem: SalesOrderItem): string {
    if (salesOrderItem.getMedicalPrescription().getPractitionerRegistration().getState().getValue()) {
      const index = this.stateList.findIndex((item) => item.getValue() === salesOrderItem.getMedicalPrescription().getPractitionerRegistration().getState().getValue());
      if (index < 0) {
        return "";
      }
      return this.stateList[index].getValue();
    }
    return "";
  }

  getRegistration(salesOrderItem: SalesOrderItem): string {
    if (salesOrderItem.getMedicalPrescription().getPractitionerRegistration().getRegistration()) {
      return salesOrderItem.getMedicalPrescription().getPractitionerRegistration().getRegistration().toString();
    }
    return "";
  }

  getMedicalPrescriptionDate(salesOrderItem: SalesOrderItem): string {
    return salesOrderItem.getMedicalPrescription().getMedicalPrescriptionDate();
  }

  isStateInputRequired(salesOrderItem: SalesOrderItem): boolean {
    const stateValue = salesOrderItem.getMedicalPrescription().getPractitionerRegistration().getState().getValue();
    const registrationValue = salesOrderItem.getMedicalPrescription().getPractitionerRegistration().getRegistration();
    const dateValue = salesOrderItem.getMedicalPrescription().getMedicalPrescriptionDate();
    if (!!stateValue) {
      return false;
    } else if (!!registrationValue || !!dateValue) {
      return true;
    }
    return salesOrderItem.getRequireMedicalPrescription();
  }

  isRegistrationInputRequired(salesOrderItem: SalesOrderItem): boolean {
    const stateValue = salesOrderItem.getMedicalPrescription().getPractitionerRegistration().getState().getValue();
    const registrationValue = salesOrderItem.getMedicalPrescription().getPractitionerRegistration().getRegistration();
    const dateValue = salesOrderItem.getMedicalPrescription().getMedicalPrescriptionDate();
    if (!!registrationValue) {
      return false;
    } else if (!!stateValue || !!dateValue) {
      return true;
    }
    return salesOrderItem.getRequireMedicalPrescription();
  }

  isDateInputRequired(salesOrderItem: SalesOrderItem): boolean {
    const stateValue = salesOrderItem.getMedicalPrescription().getPractitionerRegistration().getState().getValue();
    const registrationValue = salesOrderItem.getMedicalPrescription().getPractitionerRegistration().getRegistration();
    const dateValue = salesOrderItem.getMedicalPrescription().getMedicalPrescriptionDate();
    if (!!dateValue) {
      return false;
    } else if (!!stateValue || !!registrationValue) {
      return true;
    }
    return salesOrderItem.getRequireMedicalPrescription();
  }

  hasExternalDiscount(salesOrderItem: SalesOrderItem): boolean {
    return salesOrderItem.getProduct().getHasExternalAuthorizerBetterDiscount();
  }

  isInternetExplorer(): boolean {
    return this.navigatorService.isInternetExplorer();
  }
}
