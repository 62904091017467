import { FiscalNumber } from "src/app/ui/models/domains/pdv/fiscal-number";
import { Network } from "src/app/ui/models/domains/pdv/network";
import { PdvCycleItem } from "src/app/ui/models/domains/pdv/pdv-cycle-item";
import { PdvFinancialStatement } from "src/app/ui/models/domains/pdv/pdv-financial-statement";

export function parsePdvFinancialStatementList(payload: any): Array<PdvFinancialStatement> {
  return payload.map((item) => {
    return new PdvFinancialStatement(parseNetwork(item.Network), parsePdvCycleItemList(item.Items));
  });
}

export function parseNetwork(payload: any): Network {
  const network = new Network();
  network.setCorporateName(payload.CorporateName);
  const fiscalNumber = new FiscalNumber(payload.FiscalNumber.Value);
  network.setFiscalNumber(fiscalNumber);
  network.setTradingName(payload.TradingName);
  return network;
}

export function parsePdvCycleItemList(payload: any): Array<PdvCycleItem> {
  return payload.map((item) => {
    return new PdvCycleItem(
      item.ReferenceMonth,
      item.Period.Start,
      item.Period.End,
      item.PaymentDate,
      item.TotalPaymentValue,
      item.TotalCardValue,
      item.TotalCheckoutValue,
      item.TotalAdministrativeTax,
    );
  });
}
