import { Injectable } from "@angular/core";
import { AppContext } from "../../contexts/app-context";

import { RemoteGatewayFactory } from "../../gateways/remote-gateway-factory";
import { PageableResult } from "../../models/domains/paginator/pageable-result";
import { PagingRequest } from "../../models/domains/paginator/paging-request";
import { ProductSubtype } from "../../models/domains/pdv/Benefit/product-subtype";
import { Ean } from "../../models/domains/pdv/ean";
import { Network } from "../../models/domains/pdv/network";
import { Product } from "../../models/domains/pdv/product";
import { Shopper } from "../../models/domains/pdv/shopper";
import { BackendUrl } from "../../models/domains/urls/back-end.url";
import { FindProductByEanParser } from "../parsers/pdv/find-product-by-ean.parser";
import { FindProductSimpleListParser } from "../parsers/pdv/find-product-simple-list.parser";
import { ProductCategoryParser } from "../parsers/pdv/product-category.parser";
import { ProductSubtypeParser } from "../parsers/pdv/product-subtype.parser";
import { ProductTypeParser } from "../parsers/pdv/product-type.parser";

@Injectable()
export class ProductRepository {
  constructor(
    private remoteGatewayFactory: RemoteGatewayFactory,
    private findProductSimpleListParser: FindProductSimpleListParser,
    private findProductByEanParser: FindProductByEanParser,
    private productTypeParser: ProductTypeParser,
    private productSubtypeParser: ProductSubtypeParser,
    private productCategoryParser: ProductCategoryParser,
  ) {}

  public async findProductSimpleList(pagingRequest: PagingRequest): Promise<PageableResult> {
    const body = {
      StartIndex: pagingRequest.getStart(),
      TotalPerPages: pagingRequest.getEnd(),
      FilterText: pagingRequest.getOptions()[0].getValue(),
    };
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      const remoteGateway = this.remoteGatewayFactory.createDefaultRemoteGateway();
            const payload = await remoteGateway.postWithHeaders(new BackendUrl('/api/pbm/drugstore/products/simple'), body, headers);
      return await Promise.resolve(this.findProductSimpleListParser.parse(payload));
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  public async findProductByEan(ean: Ean, shopper: Shopper, network: Network): Promise<Product> {
    const body = {
      StartIndex: 0,
      TotalPerPages: 1,
      FilterText: ean.getValue(),
      Shopper: {
        VirtualCardNumber: shopper.getVirtualCardNumber(),
        SocialNumber: shopper.getSocialNumber().getValue(),
      },
      NetworkFiscalNumber: {
        Value: network.getFiscalNumber().getValue(),
      },
      State: {
        Name: network.getAddress().getRegion() ? network.getAddress().getRegion() : "",
        City: network.getAddress().getCity() ? network.getAddress().getCity() : "",
        District: network.getAddress().getDistrict() ? network.getAddress().getDistrict() : "",
      },
      CategoryFilterKey: {
        Value: "",
      },
      Source: "DrugstoreDesk",
    };
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      const remoteGateway = this.remoteGatewayFactory.createDefaultRemoteGateway();
            const payload = await remoteGateway.postWithHeaders(new BackendUrl('/api/pbm/drugstore/products'), body, headers);
      return await Promise.resolve(this.findProductByEanParser.parse(payload));
    } catch (httpErrorResponse) {
      throw httpErrorResponse.error.Data.ErrorMessage;
    }
  }

  public async getProductTypes(): Promise<any> {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      const remoteGateway = this.remoteGatewayFactory.createDefaultRemoteGateway();
            const data = await remoteGateway.getWithHeaders(new BackendUrl('/api/pbm/drugstore/products/types'), headers);
      return await Promise.resolve(this.productTypeParser.parse(data));
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  public async getProductSubtypeByType(typeKey: string): Promise<ProductSubtype[]> {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      const remoteGateway = this.remoteGatewayFactory.createDefaultRemoteGateway();

            const data = await remoteGateway.getWithHeaders(new BackendUrl('/api/pbm/drugstore/products/type/' + typeKey + '/subtypes'), headers);
      return await Promise.resolve(this.productSubtypeParser.parse(data));
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  public async getProductCategoryList(): Promise<any> {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      const remoteGateway = this.remoteGatewayFactory.createDefaultRemoteGateway();
      const data = await remoteGateway.getWithHeaders(new BackendUrl('/api/pbm/drugstore/products/categories'), headers);
      return await Promise.resolve(this.productCategoryParser.parse(data));
    } catch (error) {
      return await Promise.reject(error);
    }
  }
}
