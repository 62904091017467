export const environment = {
  production: true,
  appName: "PBM-PORTAL-DROGARIA-APP",
  version: "1.0.4",
  backendUrl: "https://api-mng-gip.azure-api.net/gip-pbm-api",
  mdmBackendUrl: "https://api-mng.interplayers.com.br/gip-mdm",
  authClientId: "30",
  authBaseUrl: "https://api-mng-gip.azure-api.net/gip-scsauth",
  forgotPasswordUrl: "https://idpusrauth-api.azurewebsites.net",
  forgotPasswordConfirmationUrl: "http://localhost:4200/reset-password",
  reportUrl: "https://api-mng-gip.azure-api.net/gip-rpt",
  reportServiceUrl: "https://api-mng-gip.azure-api.net/gip-rpt-svc",
  authUserTokenValidationUrl: "https://api-mng-gip.azure-api.net/gip-scs",
  authServiceTokenValidationUrl: "https://api-mng-gip.azure-api.net/gip-scs-svc",
  redirectOnExitingApp: "access-error",
  pipUrl: "https://portal.interplayers.com.br/pip/app",
  redirectUrl: "https://gip-pbm-termo-portal-drogaria-app.interplayers.com.br",
  grantType: "ClientCredentials",
};
