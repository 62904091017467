import { ResponseError } from "src/app/ui/models/domains/pdv/response-error";
import { AbstractParser } from "../parser";

export class ResponseErrorParser extends AbstractParser<ResponseError> {
  parse(payload: any): ResponseError {
    const reponseError = new ResponseError();
    reponseError.setErrorCode(payload.error.Data.ErrorCode);
    reponseError.setErrorMessage(payload.error.Data.ErrorMessage);
    return reponseError;
  }
}
