import { Injectable } from "@angular/core";
import { ToastService } from "../../services/toast.service";

@Injectable()
export class Toast {
  constructor(private toastService: ToastService) {}

  showWarningToast(text: string, title: string = "Atenção", secondsDurantion: number = 5) {
    const type = "warning";
    this.toastService.show({ type, title, text, secondsDurantion });
  }

  showSuccessToast(text: string, title: string = "", secondsDurantion: number = 5) {
    const type = "success";
    this.toastService.show({ type, title, text, secondsDurantion });
  }
}
