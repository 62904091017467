import { Injectable } from "@angular/core";
import { PageableResult } from "../../models/domains/paginator/pageable-result";
import { PagingRequest } from "../../models/domains/paginator/paging-request";
import { Ean } from "../../models/domains/pdv/ean";
import { Network } from "../../models/domains/pdv/network";
import { Product } from "../../models/domains/pdv/product";
import { Shopper } from "../../models/domains/pdv/shopper";
import { ProductRepository } from "../../repositories/pdv/product.repository";

@Injectable({ providedIn: "root" })
export class ProductService {
  constructor(private productRepository: ProductRepository) {}

  public async findProductSimpleList(pagingRequest: PagingRequest): Promise<PageableResult> {
    return await this.productRepository.findProductSimpleList(pagingRequest);
  }

  public async findProductByEan(ean: Ean, shopper: Shopper, network: Network): Promise<Product> {
    return await this.productRepository.findProductByEan(ean, shopper, network);
  }
}
