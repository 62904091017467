import { Component, Input } from "@angular/core";
import { SalesOrderItem } from "src/app/ui/models/domains/pdv/sales-order-item";

@Component({
  selector: "pdv-product-list",
  templateUrl: "./product-list.component.html",
  styleUrls: ["./product-list.component.scss"],
})
export class ProductListComponent {
  @Input() salesOrderItemList: Array<SalesOrderItem> = [];
}
