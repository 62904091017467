import { Ean } from "./ean";
import { ProgressiveDiscount } from "./progressive-discount";

export class Product {
  private authorizedQuantity: number;
  private name: string;
  private ean: Ean;
  private laboratory: string;
  private presentation: string;
  private substance: string;
  private type: string;
  private subType: string;
  private netPrice: number;
  private networkPrice: number;
  private grossPrice: number;
  private pmcPrice: number;
  private price: number;
  private productSubsidy: number;
  private discount: number;
  private progressiveDiscountList: ProgressiveDiscount[];
  private externalAuthorizerDiscount: number;
  private hasExternalAuthorizerBetterDiscount: boolean;
  private hasMedicalPrescription: boolean;
  private industryProgramAdhered: boolean;
  private usedNetworkPrice: boolean;
  private usedNetworkNetPrice: boolean;
  private usedNetworkGrossPrice: boolean;

  public setAuthorizedQuantity(value: number): void {
    this.authorizedQuantity = value;
  }

  public getAuthorizedQuantity(): number {
    return this.authorizedQuantity;
  }

  public setIndustryProgramAdhered(value: boolean): void {
    this.industryProgramAdhered = value;
  }

  public getIndustryProgramAdhered(): boolean {
    return this.industryProgramAdhered;
  }

  public setName(value: string): void {
    this.name = value;
  }

  public getName(): string {
    return this.name;
  }

  public setEan(value: Ean): void {
    this.ean = value;
  }

  public getEan(): Ean {
    return this.ean;
  }

  public setLaboratory(value: string): void {
    this.laboratory = value;
  }

  public getLaboratory(): string {
    return this.laboratory;
  }

  public setPresentation(value: string): void {
    this.presentation = value;
  }

  public getPresentation(): string {
    return this.presentation;
  }

  public getDisplayName(): string {
    return this.name + " " + this.presentation;
  }

  public setSubstance(value: string): void {
    this.substance = value;
  }

  public getSubstance(): string {
    return this.substance;
  }

  public setType(value: string): void {
    this.type = value;
  }

  public getType(): string {
    return this.type;
  }

  public setSubType(value: string): void {
    this.subType = value;
  }

  public getSubType(): string {
    return this.subType;
  }

  public setNetPrice(value: number): void {
    this.netPrice = value;
  }

  public getNetPrice(): number {
    return this.netPrice;
  }

  public setGrossPrice(value: number): void {
    this.grossPrice = value;
  }

  public getGrossPrice(): number {
    return this.grossPrice;
  }
  //
  public getNetworkPrice(): number {
    return this.networkPrice;
  }

  public setNetworkPrice(networkPrice: number): void {
    this.networkPrice = networkPrice;
  }

  public getExternalAuthorizerDiscount(): number {
    return this.externalAuthorizerDiscount;
  }

  public setExternalAuthorizerDiscount(value: number) {
    this.externalAuthorizerDiscount = value;
  }

  public getHasExternalAuthorizerBetterDiscount(): boolean {
    return this.hasExternalAuthorizerBetterDiscount;
  }

  public setHasExternalAuthorizerBetterDiscount(value: boolean) {
    this.hasExternalAuthorizerBetterDiscount = value;
  }

  public setHasMedicalPrescription(value: boolean): void {
    this.hasMedicalPrescription = value;
  }

  public getHasMedicalPrescription(): boolean {
    return this.hasMedicalPrescription;
  }

  public setDiscount(value: number): void {
    this.discount = value;
  }

  public getDiscount(): number {
    return this.discount;
  }

  public setProgressiveDiscountList(value: ProgressiveDiscount[]): void {
    this.progressiveDiscountList = value;
  }

  public getProgressiveDiscountList(): ProgressiveDiscount[] {
    return this.progressiveDiscountList;
  }

  public setProductSubsidy(value: number): void {
    this.productSubsidy = value;
  }

  public getProductSubsidy(): number {
    return this.productSubsidy;
  }

  public setPrice(value: number): void {
    this.price = value;
  }

  public getPrice(): number {
    return this.price;
  }

  public setPmcPrice(value: number): void {
    this.pmcPrice = value;
  }

  public getPmcPrice(): number {
    return this.pmcPrice;
  }

  public getUsedNetworkPrice(): boolean {
    return this.usedNetworkPrice;
  }

  public setUsedNetworkPrice(value: boolean): void {
    this.usedNetworkPrice = value;
  }

  public getUsedNetworkNetPrice(): boolean {
    return this.usedNetworkNetPrice;
  }

  public setUsedNetworkNetPrice(value: boolean): void {
    this.usedNetworkNetPrice = value;
  }

  public getUsedNetworkGrossPrice(): boolean {
    return this.usedNetworkGrossPrice;
  }

  public setUsedNetworkGrossPrice(value: boolean): void {
    this.usedNetworkGrossPrice = value;
  }

  public getIntegratedNetworkPrice(): number {
    let networkPrice: number;

    if (this.networkPrice > 0) {
      networkPrice = this.netPrice;
    } else {
      networkPrice = this.networkPrice;
    }

    return networkPrice;
  }
}
