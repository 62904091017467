export const environment = {
  authBaseUrl: "#{authBaseUrl}#",
  authServiceTokenValidationUrl: "#{authServiceTokenValidationUrl}#",
  authUserTokenValidationUrl: "#{authUserTokenValidationUrl}#",
  backendUrl: "#{backendUrl}#",
  grantType: "#{grantType}#",
  mdmBackendUrl: "#{mdmBackendUrl}#",
  pipUrl: "#{pipUrl}#",
  production: "#{production}#",
  redirectUrl: "#{redirectUrl}#",
  reportServiceUrl: "#{reportServiceUrl}#",
  reportUrl: "#{reportUrl}#",

  appName: "PBM-PORTAL-DROGARIA-APP",
  authClientId: "30",
  forgotPasswordConfirmationUrl: "http://localhost:4200/reset-password",
  forgotPasswordUrl: "https://idpusrauth-api.azurewebsites.net",
  redirectOnExitingApp: "access-error",
  version: "1.0.4",
};
