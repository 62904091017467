import { Component, OnInit } from "@angular/core";
import { MatButtonToggleGroup, MatDialog } from "@angular/material";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { AppContext } from "src/app/ui/contexts/app-context";
import { Network } from "src/app/ui/models/domains/pdv/network";
import { PdvPassport } from "src/app/ui/models/domains/pdv/pdv-passport";
import { RoutePath } from "src/app/ui/models/domains/route-paths.model";
import { ModalService } from "src/app/ui/services/modal.service";
import { NavigatorService } from "src/app/ui/services/pdv/navigator.service";
import { RouterService } from "src/app/ui/services/pdv/router.service";
import { UserService } from "src/app/ui/services/user.service";

@Component({
  selector: "pdv-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderPdvComponent implements OnInit {
  private path: Observable<string>;
  pdvPassportObservable: Observable<PdvPassport>;
  networkObservable: Observable<Network>;
  openUserPerfil: boolean;
  selectedMenuButton = "home";

  constructor(
    private userService: UserService,
    private routerService: RouterService,
    private modalService: ModalService,
    private dialog: MatDialog,
    private navigatorService: NavigatorService,
    private router: Router,
    private appContext: AppContext,
  ) {}

  ngOnInit(): void {
    this.path = this.routerService.getPath();
    this.pdvPassportObservable = this.userService.getPdvPassport();
    this.networkObservable = this.userService.getNetwork();
  }

  isAccessError(): boolean {
    let isAccessError: boolean;
    this.path.subscribe((path) => {
      isAccessError = path === RoutePath.AccessError;
    });
    return isAccessError;
  }

  isAcceptanceTerm(): boolean {
    let isAcceptanceTerm: boolean;
    this.path.subscribe((path) => {
      isAcceptanceTerm = path === RoutePath.AcceptanceTerm;
    });
    return isAcceptanceTerm;
  }

  isNotAcceptedTerm(): boolean {
    let isAcceptanceTerm: boolean;
    this.path.subscribe((path) => {
      isAcceptanceTerm = path === RoutePath.NotAcceptedTerm;
    });
    return isAcceptanceTerm;
  }

  isHome(): boolean {
    let isHome: boolean;
    this.path.subscribe((path) => {
      isHome = path === RoutePath.Home;
    });
    return isHome;
  }

  isHeadOffice(): boolean {
    return this.appContext.getNetwork().isHeadOffice();
  }

  logout(): void {
    this.userService.logout();
  }

  showUserPerfil(): void {
    this.openUserPerfil = true;
  }

  hideUserPerfil(): void {
    this.openUserPerfil = false;
    this.selectedMenuButton = this.getCurrentScreen();
  }

  openEditTerm(): void {
    this.hideUserPerfil();
    this.modalService.openTermFormModal(this.dialog, this.navigatorService.isInternetExplorer());
  }

  goToConsultSales() {
    this.router.navigate([RoutePath.Consult]);
  }

  goToFinancialStatement() {
    this.router.navigate([RoutePath.FinancialStatement]);
  }

  async goToHome() {
    const pdvPassport = this.appContext.getPdvPassport();
    this.router.navigate([RoutePath.Home], {
      queryParams: {
        t: pdvPassport.getToken(),
        f: pdvPassport.getNetworkFiscalNumber(),
        c: pdvPassport.getClientId(),
        u: pdvPassport.getOperatorUserName(),
      },
    });
  }

  getAcronym(network: Network): string {
    try {
      let acronym = "";
      const words = network.getTradingName().split(" ");
      acronym = words[0][0]; // primeira letra do nome
      if (words.length > 1) {
        // tem "sobrenome"?
        acronym += words[words.length - 1][0]; // primeira letra do ultimo sobrenome
      } else {
        acronym += words[0][1]; // segunda letra do nome
      }
      return acronym;
    } catch (error) {
      return "?";
    }
  }

  onChangeMenu(group: MatButtonToggleGroup) {
    group.value = this.getCurrentScreen() || "home";
  }

  getCurrentScreen(): string {
    const r = this.router.url.split("?")[0].replace("/", "");
    return r;
  }
}
